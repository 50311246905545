import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`

	*,
	html {
		box-sizing: border-box;
	}

	*:before,
	*:after {
		box-sizing: inherit;
	}

	/* Hide cookie-information-div on print mode */
	@media print { 
		#cookie-information-template-wrapper {
			display: none;
		}
		#header, #footer, #chat-info-popup, #start-chat {
			display: none;
		}
	}

	html,
	body {
		margin: 0;
		padding: 0;
		font-family: ${p => p.theme.typography.body.fontFamily};
		font-size: ${p => p.theme.typography.body.fontSize};
		line-height: ${p => p.theme.typography.body.lineHeight};
		color: ${p => p.theme.colors.grey900};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100vh;
		height: 100%;
	}

	body {
		overflow-x: hidden !important;
		#___gatsby, #gatsby-focus-wrapper {
			height: 100%;
		}
		&.modal-open {
			overflow-y: hidden !important;
		}
	}

	// Push-notifications-wrapper
	.ab-centering-div, .ab-iam-root.v3 {
		z-index: 999999999 !important;
	}

	main {
		display: block;
	}

	.basic-content {
		.spacing {
			.max-width {
				padding: 0;
			}
		}
		.media-group {
			> .spacing {
				padding: 0;
			}
		}
	}

	img {
		max-width: 100%;
	}

	figure {
		max-width: 100%;
		margin: 0px;
		display: block;
	}

	a {
		color: ${p => p.theme.colors.blue600};
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		cursor: pointer;
		&:hover {
			color: ${p => p.theme.colors.blue800};
		}
	}

	p {
		margin: 0px 0px 20px;
		font-size: ${p => p.theme.typography.body.fontSize};
		line-height: ${p => p.theme.typography.body.lineHeight};
	}

	li {
		${p =>
			p.theme.media.smallOnly(css`
				text-align: left;
			`)}
		P:last-of-type {
			margin: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0 0 20px 0;
		padding: 0;
		font-weight: ${p => p.theme.typography.headings.fontWeight};
		line-height: ${p => p.theme.typography.headings.lineHeight};
	}

	h1 {
		font-size: 36px;
		line-height: 46px;
		${p =>
			p.theme.media.medium(css`
				font-size: 40px;
				line-height: 50px;
			`)}
			${p =>
				p.theme.media.large(css`
					font-size: 46px;
					line-height: 56px;
				`)}

		&.large {
			${p =>
				p.theme.media.large(css`
					font-size: 72px;
					line-height: 79px;
				`)}
		}
	}

	h2, .h2 {
		font-size: 26px;
		line-height: 36px;
		${p =>
			p.theme.media.medium(css`
				font-size: 32px;
				line-height: 42px;
			`)}
	}

	h3, .h3 {
		font-size: 22px;
		line-height: 30px;
		${p =>
			p.theme.media.medium(css`
				font-size: 24px;
				line-height: 31px;
			`)}
	}

	h4, .h4 {
		font-size: 18px;
		line-height: 24px;
		${p =>
			p.theme.media.medium(css`
				font-size: 20px;
				line-height: 26px;
			`)}
	}

	h5, .h5 {
		font-size: 16px;
		line-height: 22px;
		${p =>
			p.theme.media.medium(css`
				font-size: 18px;
				line-height: 23px;
			`)}
	}

	strong,
	b {
		font-weight: 500;
	}

	mark {
		background-color: ${p => p.theme.colors.blue200};
		color: ${p => p.theme.colors.grey900};
	}

	input {
		background-color: ${p => p.theme.colors.grey200}; 
		padding: ${p => p.theme.forms.input.padding}; 
		display: block;
		border: ${p => p.theme.colors.grey400};
		width: 100%;
		font-size: ${p => p.theme.typography.body.fontSize};
		line-height: ${p => p.theme.typography.body.lineHeight};
		border-radius: ${p => p.theme.utils.borderRadius};
		transition: all 0.2s ease-out;
		&:focus {
			outline: ${p => p.theme.colors.blue600};
			outline-offset: -2px;
			background-color: ${p => p.theme.colors.white};
		}
		&:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
			min-height: ${p => p.theme.forms.input.height};
		}

		// Remove webkit search decorations
		&[type="search"]::-webkit-search-decoration,
		&[type="search"]::-webkit-search-cancel-button,
		&[type="search"]::-webkit-search-results-button,
		&[type="search"]::-webkit-search-results-decoration {
			display: none; 
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${p => p.theme.colors.grey900};
			opacity: 0.7;
			/* Firefox */
		}
	
		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: ${p => p.theme.colors.grey900};
		}
	
		::-ms-input-placeholder {
			/* Microsoft Edge */
			color: ${p => p.theme.colors.grey900};
		}
	}

	textarea {
		width: 100%;
		background-color: ${p => p.theme.colors.grey200}; 
		padding: ${p => p.theme.forms.input.padding};
		display: block;
		border: ${p => p.theme.colors.grey400};
		width: 100%;
		font-size: ${p => p.theme.typography.body.fontSize};
		line-height: ${p => p.theme.typography.body.lineHeight};
		border-radius: ${p => p.theme.utils.borderRadius};
		transition: all 0.2s ease-out;
		font-family: inherit;
		&:focus {
			outline: ${p => p.theme.colors.blue600};
			outline-offset: -2px;
			background-color: ${p => p.theme.colors.white};
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${p => p.theme.colors.grey900};
			opacity: 0.7;
			/* Firefox */
		}
	
		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: ${p => p.theme.colors.grey900};
		}
	
		::-ms-input-placeholder {
			/* Microsoft Edge */
			color: ${p => p.theme.colors.grey900};
		}
	}

	blockquote {
		margin: 63px 15px 40px 40px;
		position: relative;
		&:before {
			content: '"';
			background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-quote-left fa-w-16 fa-2x'%3E%3Cpath fill='currentColor' d='M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z' class=''%3E%3C/path%3E%3C/svg%3E");
			width: 25px;
			height: 28px;
			background-size: 100% auto;
			background-repeat: no-repeat;
			display: block;
			position: absolute;
			left: -40px;
			top: 0px;
		}
		p {
			font-size: 20px;
			line-height: 1.38;
			color: ${p => p.theme.colors.grey900};
			margin-bottom: 0px;
		}
		small {
			color: ${p => p.theme.colors.grey700};
			font-weight: normal;
		}
		${p =>
			p.theme.media.medium(css`
				margin: 63px 68px 63px 96px;
				&:before {
					width: 32px;
					left: -49px;
				}
				p {
					font-size: 21px;
					line-height: 33px;
				}
			`)}
	}

	fieldset {
		padding: 0;
		margin: 0 auto;
		border: none;
	}

	figure.center {
		text-align: center;
	}

	.clearfix {
		*zoom: 1;
		&:before,
		&:after {
			content: " ";
			display: table;
		}
		&:after {
			clear: both;
		}
	}

	button, .button, [type="submit"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		cursor: pointer;
		border: none;
		font-family: inherit;
		font-size: ${p => p.theme.typography.body.fontSize};
		&:hover {
			border: none;
		}
	}

	/* Text meant only for screen readers. */
	.screen-reader-text {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}
	.screen-reader-text:focus {
		background-color: ${p => p.theme.colors.grey300};
		clip: auto !important;
		clip-path: none;
		color: ${p => p.theme.colors.grey900};
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	}


`;

export default GlobalStyle;
