import React from 'react';
import http from 'stream-http';

import ThemeProvider from './src/context/ThemeProvider';

if (typeof window !== 'undefined') {
	global.http = http;
}

export const wrapPageElement = ({ element }) => {
	return <ThemeProvider>{element}</ThemeProvider>;
};
